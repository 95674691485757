<!--
 * @Date: 2021-01-31 10:52:22
 * @LastEditors: frank
 * @LastEditTime: 2021-05-03 20:53:06
 * @FilePath: /shop_frontend/src/views/memberCard/add.vue
 * @Description: Description
-->
<template>
  <section class="add">
    <div class="add__content">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <div class="block">
          <div class="block__title">基础设置</div>
          <a-form-model-item label="本卡是否使用">
            <a-radio-group v-model="form.is_active" :options="useOptions" />
          </a-form-model-item>
          <a-form-model-item label="会员卡名称" prop="name">
            <a-input class="w220" v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="背景图" prop="background" required>
            <a-upload
              name="image"
              list-type="picture-card"
              :show-upload-list="false"
              action="/api/card_backgrounds/"
              :before-upload="beforeUpload"
              :withCredentials="true"
              :headers="headers"
              @change="uploadChange"
            >
              <img
                style="display: inline-block; width: 375px; height: 140px"
                v-if="imageUrl"
                :src="imageUrl"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span style="color: red;">建议上传图片尺寸375px*140px</span>
          </a-form-model-item>
          <!-- <a-form-model-item label="使用须知">
            <a-textarea
              v-model="form.instruction"
              :auto-size="{ minRows: 4, maxRows: 6 }"
            />
          </a-form-model-item> -->
          <!-- <a-form-model-item label="联系电话">
            <a-input class="w220" v-model="form.contact_number"></a-input>
          </a-form-model-item> -->
            <a-form-model-item label="文本颜色">
             <div class="color__box"><colorPicker v-model="form.font_color" /></div>
          </a-form-model-item>
         
        </div>
        <div class="block">
          <div class="block__title">会员权益</div>
          <a-form-model-item label="会员权益">
            <!-- <div>
              <a-checkbox v-model="form.open_discount"> 会员折扣 </a-checkbox>
              <a-input class="w120" v-model="form.discount"></a-input>
              <span style="margin-left: 10px">折</span>
            </div> -->
            <!-- <div>
              <a-checkbox> 积分获取 </a-checkbox>
            </div> -->
            <div>
              <a-checkbox v-model="form.open_integral"> 购物送积分 </a-checkbox>
              <div>
                <span>购物每满</span>
                <a-input
                  class="w120"
                  style="margin-left: 10px"
                  v-model="form.amount"
                ></a-input>
                <span style="margin-left: 10px; margin-right: 10px">送</span>
                <a-input class="w120" v-model="form.points"></a-input>
                <span style="margin-left: 10px">积分</span>
                <!-- <span style="margin-left: 10px"
                  ><a-icon type="plus-circle"
                /></span> -->
              </div>
              <!-- <div v-for="(item, index) in integralRulesOut" :key="index">
                <span>购物每满</span>
                <a-input
                  class="w120"
                  style="margin-left: 10px"
                  v-model="item.amount"
                ></a-input>
                <span style="margin-left: 10px; margin-right: 10px">送</span>
                <a-input class="w120" v-model="item.points"></a-input>
                <span style="margin-left: 10px">积分</span>
               <span style="margin-left: 10px"
                  ><a-icon type="plus-circle"
                /></span> 
              </div> -->
            </div>
            <div>
              <a-checkbox v-model="form.open_freight_free"> 包邮 </a-checkbox>
              <span style="opacity: 0.7">持有此会员卡的用户可享受包邮</span>
            </div>
          </a-form-model-item>
        </div>
        <div class="block">
          <div class="block__title">升级设置</div>
          <a-form-model-item label="会员卡等级" prop="level">
            <a-select style="width: 220px" v-model="form.level">
              <a-select-option
                :value="item"
                v-for="item in levelList"
                :key="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="所需累计积分" prop="upgrade_points">
            <a-input
              class="w220"
              v-model="form.upgrade_points"
              type="number"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="升级开关">
            <a-radio-group v-model="form.open_upgrade" :options="useOptions" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <div class="add__btn flex">
      <a-button @click="$router.go(-1)">取消</a-button>
      <a-button type="primary" style="margin-left: 20px" @click="save"
        >保存</a-button
      >
    </div>
  </section>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "add",
  data() {
    let validateImage = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请选择背景图"));
      } else {
        callback();
      }
    };
    return {
      form: {
        is_active: true,
        name: "",
        background: "",
        instruction: "",
        contact_number: "",
        level: "",
        discount: 0,
        open_discount: false,
        open_freight_free: false,
        open_integral: false,
        open_upgrade: true,
        integral_rules: [],
        upgrade_points: 0,
        amount: 0,
        points: 0
      },
      integralRulesOut: [
        {
          amount: 0,
          points: 0,
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入会员卡名称",
            trigger: "blur",
          },
        ],
        upgrade_points: [
          {
            required: true,
            message: "请输入所需累计积分",
            trigger: "blur",
          },
        ],
        background: [{ validator: validateImage, trigger: "change" }],
        level: [
          {
            required: true,
            message: "请选择会员卡等级",
            trigger: "change",
          },
        ],
      },
      useOptions: [
        { label: "启用", value: true },
        { label: "停用", value: false },
      ],
      levelList: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      loading: false,
      imageUrl: "",
    };
  },
  components: {},
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
    id() {
      return this.$route.query.id || "";
    },
  },
  watch: {},
  created() {},
  mounted() {
    if (this.id) {
      console.log(this.id, "id---");
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios.get(`/cards/${this.id}/`).then((res) => {
        console.log(res, "edit");
        this.form = res;
        this.imageUrl = res.background_url;
        this.integralRulesOut = res.integral_rule_items || [];
      });
    },
    uploadChange({ file }) {
      console.log(file);
      if (file.status == "done") {
        this.loading = false;
        this.imageUrl = file.response.image;
        this.form.background = file.response.id;
      }
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        this.loading = true;
        return true;
      }
    },
    save() {
      console.log(this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.id
            ? this.$axios.put("/cards/" + this.id + "/", this.form)
            : this.$axios.post("/cards/", this.form);

            console.log(request, 'request')

          request.then((res) => {
            console.log(res, 'rrrr---')
            // let id = res.id || "";
            // console.log(this.form.open_integral , id, '======')
            // if (this.form.open_integral && id) {
            //   this.integralRulesOut.forEach((el) => {
            //     console.log(el, "el---===");
            //     if (el.id) {
            //       this.$axios.put("/card_integral_rules/" + el.id + "/", {
            //         card: id,
            //         amount: el.amount,
            //         points: el.points,
            //       });
            //     } else {
            //       this.$axios.post("/card_integral_rules/", {
            //         card: id,
            //         amount: el.amount,
            //         points: el.points,
            //       });
            //     }
            //   });
            // }
            setTimeout(() => {
              this.$router.replace({ name: "memberCardShow" });
            }, 800);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.add {
  height: 100%;
  position: relative;
  padding-bottom: 40px;
  &__content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  &__btn {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    justify-content: center;
    width: 100%;
  }
}
.block {
  padding: 20px 0;
  background: #f8f8f8;
  margin-bottom: 20px;
  &__title {
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
}
</style>
